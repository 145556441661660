/**
 * 1. Le agrega la clase scrolled , scrolled-100, scrolled-300 y scrolled-500 al body
 * cuando el usuario hace scroll en diferentes partes de la pagaina
 *
 */
(function() {

  // Se encarga de disparar la funcion classesOnScroll
  // var debounceScroll;
  // window.addEventListener("scroll", function() {
  //   // Evita que paralice la página ya que scroll genera demasiados eventos
  //   if (debounceScroll) {
  //     window.clearTimeout(debounceScroll);
  //   }
  //   debounceScroll = window.setTimeout(classesOnScroll, 50);
  // });

// Cuando hay scroll cambiamos las clases del body. Lo hacemos con throttle para 
// que no consuma mucha CPU
window.addEventListener('scroll', throttle(classesOnScroll, 700));

/**
 * Evita los problemas de performance que tienen el ejecutar un callback con scroll
 * 
 * @param {function} fn Callback
 * @param {int} wait Tiempo a esperar
 */
  function throttle(fn, wait) {
    var time = Date.now();
    return function() {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      } else {
        window.setTimeout(classesOnScroll, 2000)
      }
    };
  }

  /**
   * Dependiendo de que tanto se haya hecho scroll, le agrega una clase al body
   */
  function classesOnScroll() {
    if (window.scrollY < 100) {
      if (!documentBodyClassContainsAll(["scrolled"])) {
        document.body.classList.add("scrolled");
      }
      if (
        documentBodyClassContainsOne([
          "scrolled-100",
          "scrolled-300",
          "scrolled-500"
        ])
      ) {
        document.body.classList.remove(
          "scrolled-100",
          "scrolled-300",
          "scrolled-500"
        );
      }
    }

    if (window.scrollY >= 100 && window.scrollY < 300) {
      if (!documentBodyClassContainsAll(["scrolled", "scrolled-100"])) {
        document.body.classList.add("scrolled", "scrolled-100");
      }
      if (documentBodyClassContainsOne(["scrolled-300", "scrolled-500"])) {
        document.body.classList.remove("scrolled-300", "scrolled-500");
      }
    }

    if (window.scrollY >= 300 && window.scrollY < 500) {
      if (!documentBodyClassContainsAll(["scrolled", "scrolled-300"])) {
        document.body.classList.add("scrolled", "scrolled-300");
      }
      if (documentBodyClassContainsOne(["scrolled-100", "scrolled-500"])) {
        document.body.classList.remove("scrolled-100", "scrolled-500");
      }
    }

    if (window.scrollY >= 500) {
      if (!documentBodyClassContainsAll(["scrolled", "scrolled-500"])) {
        document.body.classList.add("scrolled", "scrolled-500");
      }
      if (documentBodyClassContainsOne(["scrolled-100", "scrolled-300"])) {
        document.body.classList.remove("scrolled-100", "scrolled-300");
      }
    }
  }

  window.addEventListener("resize", function() {
    if (typeof stickyElement != "undefined" && stickyElement.length > 0) {
      var rect = stickyElement[0].getBoundingClientRect();
      stickyElementOffset = rect.top;
      addStickyCss(
        dz_sticky_header.selector,
        rect.top,
        rect.left,
        rect.width,
        rect.height
      );
    }
  });

  /**
   * Verifica si un elemento contine UNA clase especifica (entre todas las posibles)
   */
  function documentBodyClassContainsOne(list) {
    for (var i = 0; i < list.length; i++) {
      if (document.body.classList.contains(list[i])) {
        return true;
      }
    }
    return false;
  }

  /**
   * Verifica si un elemento contine TODAS las clases especificadas
   * Si falta una clase entonces regresa false
   *
   * @param {array} list list Lista de clases que se deben verificar
   */
  function documentBodyClassContainsAll(list) {
    for (var i = 0; i < list.length; i++) {
      if (!document.body.classList.contains(list[i])) {
        return false;
      }
    }
    return true;
  }
})();
